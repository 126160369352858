<template>
  <!--      :style="creationData.length > 0 ? '' : 'border-bottom: none'"-->
  <div class="scientificPublication" style="padding-top: 4px;">
    <div class="scientificPublication__row">
      <div class="inputDiv" style="width: 1145px; margin-right: 0;">
        <label>Область достижения <span style="color: red">*</span></label>
        <v-autocomplete
          :rules="[rules.required]"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
          v-model="creationData.articleArea"
          :items="eventAreaArray"
        ></v-autocomplete>
      </div>
    </div>
    <div class="scientificPublication__row">
      <div class="inputDiv" style="width: 365px">
        <label>Вид мероприятия <span style="color: red">*</span></label>
        <v-autocomplete
          :rules="[rules.required]"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
          v-model="creationData.articleEventType"
          :items="eventTypeArray"
          @change="creationData.articleEventTypeStr = ''"
        ></v-autocomplete>
      </div>
      <div class="inputDiv" style="width: 755px; margin-right: 0">
        <label>Иное мероприятие <span v-if="creationData.articleEventType === 82" style="color: red">*</span></label>
        <v-text-field
          :rules="[rules.required]"
          v-model="creationData.articleEventTypeStr"
          :disabled="!blockEditingInProcess || creationData.articleEventType !== 82"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
        ></v-text-field>
      </div>
    </div>
    <div class="scientificPublication__row">
      <div class="inputDiv" style="width: 365px">
        <label>Статус мероприятия <span style="color: red">*</span></label>
        <v-autocomplete
          :rules="[rules.required]"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
          v-model="creationData.articleEventStatus"
          :items="eventStatusArray"
        ></v-autocomplete>
      </div>
      <!--      <div class="inputDiv" style="width: 755px; margin-right: 0">-->
      <!--        <label>Ввод статуса <span v-if="creationData.articleEventType === 82" style="color: red">*</span></label>-->
      <!--        <v-text-field-->
      <!--          :rules="[rules.required]"-->
      <!--          v-model="creationData.articleEventStatusStr"-->
      <!--          :disabled="!blockEditingInProcess || creationData.articleEventType !== 82"-->
      <!--          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"-->
      <!--        ></v-text-field>-->
      <!--      </div>-->
    </div>
    <!--    v-if="blockOpened"-->
    <div class="scientificPublication__row">
      <div class="inputDiv" style="width: 1145px; margin-right: 0">
        <label>Название мероприятия <span style="color: red">*</span></label>
        <v-text-field
          :rules="[rules.required]"
          v-model="creationData.articleEventName"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
        ></v-text-field>
      </div>
    </div>
    <v-dialog
      v-model="invalidData"
      width="500px"
    >
      <v-card>
        <v-card-title style="margin: 0 auto">
          {{ 'Требуется заполнить все обязательные поля' }}
        </v-card-title>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="wrongFileTypeDialog"
      width="665px"
    >
      <v-card>
        <v-card-title style="margin: 0 auto">
          {{ 'Загруженный формат скана не соответствует расширению .pdf' }}
        </v-card-title>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="deleteConfirmDialog"
      width="375"
    >
      <v-card>
        <v-card-title>Подтверждение удаления строки</v-card-title>
        <v-card-actions style="justify-content: center">
          <v-btn class="dialog-btn" @click="deleteRow">Да</v-btn>
          <v-btn class="dialog-btn" @click="deleteConfirmDialog = false">Нет</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="scientificPublication__row">
      <div class="inputDiv" style="width: 365px">
        <label>Наличие соавторов <span style="color: red">*</span></label>
        <v-autocomplete
          :rules="[rules.required]"
          v-model="creationData.hasCoauthors"
          :items="articleCoauthorsOptions"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
        ></v-autocomplete>
      </div>
      <div class="inputDiv" style="width: 170px">
        <label>Месяц начала <span style="color: red">*</span></label>
        <v-autocomplete
          :rules="[rules.required]"
          v-model="creationData.articleMonthStart"
          :items="eventMonthsArray"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
        ></v-autocomplete>
      </div>
      <div class="inputDiv" style="width: 170px">
        <label>Год начала <span style="color: red">*</span></label>
        <v-autocomplete
          :rules="[rules.required]"
          v-model="creationData.articleYearStart"
          :items="eventYearArray"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
        ></v-autocomplete>
      </div>
      <div class="inputDiv" style="width: 170px">
        <label>Месяц окончания <span style="color: red">*</span></label>
        <v-autocomplete
          :rules="[rules.required]"
          v-model="creationData.articleMonthEnd"
          :items="eventMonthsArray"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
        ></v-autocomplete>
      </div>
      <div class="inputDiv" style="width: 170px; margin-right: 0">
        <label>Год окончания <span style="color: red">*</span></label>
        <v-autocomplete
          :rules="[rules.required]"
          v-model="creationData.articleYearEnd"
          :items="eventYearArray"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
        ></v-autocomplete>
      </div>
    </div>
    <div class="scientificPublication__row" v-if="creationData.hasCoauthors">
      <div class="inputDiv" style="width: 755px">
        <label>Соавторы <span style="color: red">*</span></label>
        <v-text-field
          v-model="creationData.articleCoauthors"
          :disabled="!blockEditingInProcess"
          :style="blockEditingInProcess ? 'background: #E3EFFA' : ''"
        ></v-text-field>
      </div>
    </div>
    <div class="scientificPublication__row">
      <div class="fileDiv" style="margin-top: 20px; width: 460px; margin-right: 25px;">
        <v-file-input
          prepend-icon="mdi-folder-open-outline" v-model="creationData.scanIdSysScan"
          accept="application/pdf" :style="creationData.sysScanFileUuid ? 'display: none' : ''"
          placeholder="Выберите файл"
          @change="wrongFileTypeDialogFunc($event)"
        ></v-file-input>
        <div class="file__info">
          <p class="file__text" style="min-width: 105px;" v-if="!creationData.sysScanFileUuid">Скан-копия
            подтверждающего мероприятие документа</p>
          <p
            class="file__text file__text_link" style="min-width: 105px;" v-else
            @click="getFile(creationData.sysScanFileUuid, creationData.sysScanFileNameOrig, creationData.sysScanFileExt)">
            Скан-копия подтверждающего мероприятие документа
          </p>
          <p class="file__status" v-if="creationData.scanIdSysScan">Загружен</p>
          <p class="file__status file__status_empty" v-else>Не сохранено</p>
        </div>
        <img
          v-if="creationData.sysScanFileUuid" src="@/assets/icons/close-red-round.svg"
          alt="delete" class="fileDiv__delete" @click="deleteFile(creationData.id)">
      </div>
    </div>
    <div class="btnDiv" style="padding: 22px 0 0 0; border-top: none">
      <button
        class="usual-btn" :disabled="!blockEditingInProcess" @click="updateInfo"
      >
        Сохранить
      </button>
      <button class="usual-btn usual-btn_grey" @click="getAllBlock">Отмена</button>
      <p class="block__hint"><span style="color: red">*</span> Все поля, помеченные звездочкой, обязательны для
        заполнения</p>
      <button
        v-if="block2Data.id"
        class="usual-btn usual-btn_delete" style="margin-left: auto" @click="deleteConfirmFunc"
      >
        Удалить
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex";
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

export default {
  name: "Block2Creation",
  components: {},
  data() {
    return {
      loading: false,
      invalidData: false,
      blockEditingInProcess: true,
      deleteConfirmDialog: false,
      wrongFileTypeDialog: false,
      wrongFileType: false,
      creationData: {
        id: 0,
        articleArea: 0,
        articleEventType: 0,
        articleEventTypeStr: "",
        articleEventName: "",
        articleEventStatus: 0,
        articleEventStatusStr: "",
        articleYearStart: 0,
        articleMonthStart: 0,
        articleYearEnd: 0,
        articleMonthEnd: 0,
        scanIdSysScan: null,
        articleCoauthors: "",
        hasCoauthors: null,
      },
      articleCoauthorsOptions: [
        {text: 'Да', value: true},
        {text: 'Нет', value: false},
      ],
      eventYearArray: [
        {text: '2022 год', value: 2022},
        {text: '2023 год', value: 2023},
        // {text: '2024 год', value: 2024},
      ],
      eventMonthsArray: [],
      eventTypeArray: [],
      eventStatusArray: [],
      eventAreaArray: [],
      rules: {
        required: value => !!value || 'Обязательное поле!',
      }
    }
  },
  props: {
    rowID: {
      type: String,
      default: ''
    },
    block2Data: {
      type: Object,
      default: function () {
        return {}
      },
    },
    applicantBlocked: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      // getUser: 'getUser',
    }),
    fieldsValidation() {
      if (this.creationData.articleEventName && this.creationData.articleArea
        && (this.creationData.articleEventType !== 82 || this.creationData.articleEventTypeStr)
        && this.creationData.articleEventStatus && this.creationData.articleMonthStart && this.creationData.articleMonthEnd
        && this.creationData.articleYearStart && this.creationData.articleYearEnd
        && (((this.creationData.hasCoauthors === true && this.creationData.articleCoauthors)) || this.creationData.hasCoauthors === false)) {
        return true
      } else {
        return false
      }
    },
  },
  created() {
    this.createdFunc();
  },
  methods: {
    async createdFunc() {
      await this.getSprArea();
      await this.getSprEventType();
      await this.getSprEventStatus();
      await this.getSprMonths();
      
      if (this.block2Data.id) { // проверка на создание/редактирование
        this.creationData = this.block2Data;
      }
    },
  
    wrongFileTypeDialogFunc(arg) {
      if (arg.type !== 'application/pdf') {
        this.wrongFileTypeDialog = true;
        this.wrongFileType = true;
      } else {
        this.wrongFileType = false;
      }
    },
    
    async getSprArea() {
      this.eventAreaArray = [];
      try {
        const res = await axios.get(`/api/2024/spr/textValMns/get?block=4&position=1`, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          for (let i = 0; i < res.data.length; i++) {
            this.eventAreaArray.push({
              text: res.data[i].value,
              value: res.data[i].id
            })
          }
          console.log('getSprArea успех');
        }
      } catch (e) {
        console.log(e);
      }
    },
    
    async getSprEventType() {
      this.eventTypeArray = [];
      try {
        const res = await axios.get(`/api/2024/spr/textValMns/get?block=4&position=2`, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          for (let i = 0; i < res.data.length; i++) {
            this.eventTypeArray.push({
              text: res.data[i].value,
              value: res.data[i].id
            })
          }
          console.log('getSprEventType успех');
        }
      } catch (e) {
        console.log(e);
      }
    },
    
    async getSprEventStatus() {
      this.eventStatusArray = [];
      try {
        const res = await axios.get(`/api/2024/spr/textValMns/get?block=4&position=4`, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          for (let i = 0; i < res.data.length; i++) {
            this.eventStatusArray.push({
              text: res.data[i].value,
              value: res.data[i].id
            })
          }
          console.log('getSprEventStatus успех');
        }
      } catch (e) {
        console.log(e);
      }
    },
    
    async getSprMonths() {
      this.eventMonthsArray = [];
      try {
        const res = await axios.get(`/api/2024/spr/textValMns/get?block=4&position=0`, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          for (let i = 0; i < res.data.length; i++) {
            this.eventMonthsArray.push({
              text: res.data[i].value,
              value: res.data[i].id
            })
          }
          console.log('getSprMonths успех');
        }
      } catch (e) {
        console.log(e);
      }
    },
    
    async updateInfo() {
      if (this.fieldsValidation && !this.wrongFileType) {
        let url = `/api/2024/mns/block4/add`;
        if (this.creationData.id !== 0) {
          url = `/api/2024/mns/block4/update`
        }
        const formData = new FormData();
        if (this.creationData.scanIdSysScan && typeof this.creationData.scanIdSysScan !== "number") {
          formData.append('file', this.creationData.scanIdSysScan);
        }
        formData.append('data', new Blob([JSON.stringify({
          "id": this.creationData.id !== 0 ? this.creationData.id : '',
          "mainId": this.rowID,
          "hasCoauthors": this.creationData.hasCoauthors,
          "articleCoauthors": this.creationData.hasCoauthors ? this.creationData.articleCoauthors : '',
          "articleArea": this.creationData.articleArea,
          "articleEventType": this.creationData.articleEventType,
          "articleEventTypeStr": this.creationData.articleEventType === 82 ? this.creationData.articleEventTypeStr : '',
          "articleEventStatus": this.creationData.articleEventStatus,
          "articleEventStatusStr": this.creationData.articleEventStatusStr,
          "articleEventName": this.creationData.articleEventName,
          "articleMonthStart": this.creationData.articleMonthStart,
          "articleMonthEnd": this.creationData.articleMonthEnd,
          "articleYearStart": this.creationData.articleYearStart,
          "articleYearEnd": this.creationData.articleYearEnd,
        })], {
          type: "application/json"
        }));
        try {
          const res = await axios.post(url, formData,
            {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
          if (res.status === 200 && res.data) {
            this.$emit("updateData"); // вызов getAll в корневом файле
            this.blockEditingInProcess = false;
            this.loading = false;
            console.log('updateInfo успех');
          }
        } catch (e) {
          this.loading = false;
          console.log(e);
        }
      } else if (this.wrongFileType) {
        this.wrongFileTypeDialog = true
      } else {
        this.invalidData = true
      }
    },
    
    getAllBlock() {
      this.$emit("updateData");
    },
    
    deleteConfirmFunc() {
      this.deleteConfirmDialog = true;
    },
    
    async deleteRow() {
      try {
        const res = await axios.delete(`/api/2024/mns/block4/delete?rowId=${this.block2Data.id}`, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          console.log('deleteRow успех');
          this.$emit("updateData");
        }
      } catch (e) {
        console.log(e);
      }
    },
    
    async deleteFile(ID) {
      this.loading = true;
      let uri = `/api/2024/mns/block4/scan/delete?rowId=${ID}`;
      try {
        const res = await axios.delete(uri, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          this.creationData.scanIdSysScan = null;
          this.creationData.sysScanFileUuid = null;
          this.loading = false;
          console.log('deleteFile успех');
        }
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
    
    async getFile(id, name, ext) {
      let uri = `/api/2024/mns/blocks/scan/download?sysScanFileUuid=${id}`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`}
      })
      this.saveByteArray(`${name}.${ext}`, responseAxios.data, ext)
    },
    
    saveByteArray(reportName, byte, ext) {
      let blob = new Blob([byte], {type: `application/${ext}`});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank'; // вариант открытия в новом окне
      // link.download = reportName; // вариант скачивания
      link.click();
    },
  }
}
</script>

<style scoped>

</style>