<template>
  <div v-if="loading" style="min-height: 200px" class="d-flex flex-row align-center">
    <v-progress-linear indeterminate></v-progress-linear>
  </div>
  <div v-else class="personInfoBlock">
    <div class="personInfo__headerDiv" style="display: flex; flex-direction: row">
      <p class="personInfo__header">Информация о претенденте на стипендию</p>
      <img
        v-if="blockOpened"
        @click="blockOpened = !blockOpened"
        src="@/assets/icons/block_opened_blue.svg"
        class="btn_open"
        alt=""
      />
      <img
        v-else
        @click="blockOpened = !blockOpened"
        src="@/assets/icons/block_closed_blue.svg"
        class="btn_open"
        alt=""
      />
    </div>
    <div class="fioBlock">
      <div class="inputDiv">
        <label>Фамилия <span style="color: red">*</span></label>
        <v-text-field
          :rules="[rules.required]"
          v-model="form.mainLastName"
          :disabled="!editionMode"
          :style="editionMode ? 'background: #E3EFFA' : ''"
        >
        </v-text-field>
      </div>
      <div class="inputDiv">
        <label>Имя</label>
        <v-text-field
          v-model="form.mainFirstName"
          :disabled="!editionMode"
          :style="editionMode ? 'background: #E3EFFA' : ''"
        ></v-text-field>
      </div>
      <div class="inputDiv">
        <label>Отчество (при наличии)</label>
        <v-text-field
          v-model="form.mainSecondName"
          :disabled="!editionMode"
          :style="editionMode ? 'background: #E3EFFA' : ''"
        ></v-text-field>
      </div>
      <div class="inputDiv" style="width: 170px; margin-right: 0">
        <label>Пол <span style="color: red">*</span></label>
        <v-autocomplete
          :rules="[rules.required]"
          :disabled="!editionMode"
          :style="editionMode ? 'background: #E3EFFA' : ''"
          v-model="form.mainGender"
          :items="gendersArray"
        ></v-autocomplete>
      </div>
      <div class="inputDiv" style="width: 1145px; margin-right: 0">
        <div class="scientificPublication__row" style="margin-bottom: -10px; margin-top: -10px">
          <v-checkbox :disabled="!editionMode" v-model="prevFio">
            <template v-slot:label>
              <div>Изменялись ли за последние 2 года фамилия/имя/отчество претендента?</div>
            </template>
          </v-checkbox>
        </div>
      </div>
      <div class="inputDiv" v-if="prevFio">
        <label>Прежняя фамилия</label>
        <v-text-field
          v-model="form.mainEdit2022OldLastName"
          :disabled="!editionMode"
          :style="editionMode ? 'background: #E3EFFA' : ''"
        ></v-text-field>
      </div>
      <div class="inputDiv" v-if="prevFio">
        <label>Прежнее имя</label>
        <v-text-field
          v-model="form.mainEdit2022OldFirstName"
          :disabled="!editionMode"
          :style="editionMode ? 'background: #E3EFFA' : ''"
        ></v-text-field>
      </div>
      <div class="inputDiv" v-if="prevFio">
        <label>Прежнее отчество (при наличии)</label>
        <v-text-field
          v-model="form.mainEdit2022OldSecondName"
          :disabled="!editionMode"
          :style="editionMode ? 'background: #E3EFFA' : ''"
        ></v-text-field>
      </div>
    </div>
    <div class="citizenship" v-if="blockOpened">
      <div class="inputDiv">
        <label>Гражданство <span style="color: red">*</span></label>
        <v-autocomplete
          :disabled="!editionMode"
          :style="editionMode ? 'background: #E3EFFA' : ''"
          v-model="form.mainEdit2022IsForeign"
          :items="citizenshipArray"
        ></v-autocomplete>
      </div>
      <div class="inputDiv" style="width: 630px; margin-right: 0">
        <label>
          Принадлежность к малочисленным народам Севера, Сибири и Дальнего Востока <span style="color: red">*</span>
        </label>
        <v-autocomplete
          :disabled="!editionMode"
          :style="editionMode ? 'background: #E3EFFA' : ''"
          v-model="form.mainIsMns"
          :items="isMnsOptions"
        ></v-autocomplete>
      </div>
    </div>
    <div class="contacts">
      <div class="inputDiv">
        <label>Электронная почта <span style="color: red">*</span></label>
        <v-text-field
          :rules="[rules.required, rules.email]"
          v-model="form.mainEmail"
          :disabled="!editionMode"
          :style="editionMode ? 'background: #E3EFFA' : ''"
        ></v-text-field>
      </div>
      <div class="inputDiv">
        <label>Мобильный телефон <span style="color: red">*</span></label>
        <v-text-field
          :rules="[rules.required]"
          v-model="form.mainPhoneNumber"
          :disabled="!editionMode"
          :style="editionMode ? 'background: #E3EFFA' : ''"
        ></v-text-field>
      </div>
    </div>
    <div class="university" v-if="blockOpened">
      <p class="bold" style="margin: 13px auto 0 5px">Сведения на момент подачи заявки</p>
      <div style="display: flex; flex-direction: row">
        <div class="inputDiv" style="width: 405px">
          <label>Курс/год обучения <span style="color: red">*</span></label>
          <v-autocomplete
            :rules="[rules.required]"
            :disabled="!editionMode"
            :style="editionMode ? 'background: #E3EFFA' : ''"
            v-model="form.mainCourse"
            :items="coursesArray"></v-autocomplete>
        </div>
        <div class="inputDiv" style="width: 345px">
          <label>Срок обучения <span style="color: red">*</span></label>
          <v-text-field
            :rules="[rules.required]"
            :disabled="!editionMode || !form.mainCourse"
            v-model.number="form.mainSrokObuchenia"
            :style="editionMode ? 'background: #E3EFFA' : ''"></v-text-field>
        </div>
        <div class="inputDiv" style="width: 345px; margin-right: 0">
          <label>Уровень подготовки <span style="color: red">*</span></label>
          <v-autocomplete
            :rules="[rules.required]"
            :disabled="!editionMode"
            :style="editionMode ? 'background: #E3EFFA' : ''"
            v-model="form.mainSpec.usl.id"
            :items="spr.usl"
            @change="levelChange(form.mainSpec.usl.id, 'not-initial')"
          ></v-autocomplete>
          <!--        form.mainSpec.usl.id !== 6-->
        </div>
      </div>
      <div class="inputDiv" style="width: 405px">
        <label>Направление подготовки (специальность) <span style="color: red">*</span></label>
        <v-autocomplete
          :rules="[rules.required]"
          :disabled="!editionMode || !form.mainSpec.usl.id"
          :style="editionMode ? 'background: #E3EFFA' : ''"
          v-model="form.mainSpec.id"
          :items="spr.spec"
        ></v-autocomplete>
      </div>
      <div class="inputDiv" style="width: 345px">
        <label>Форма обучения <span style="color: red">*</span></label>
        <v-autocomplete
          :rules="[rules.required]"
          :style="editionMode ? 'background: #E3EFFA' : ''"
          :disabled="!editionMode || !form.mainSpec.usl.id"
          v-model="form.mainFo.id"
          :items="spr.fo"
        ></v-autocomplete>
      </div>
      <div class="inputDiv" style="width: 345px; margin-right: 0">
        <label>Форма финансирования <span style="color: red">*</span></label>
        <v-autocomplete
          :rules="[rules.required]"
          :disabled="!editionMode || !form.mainSpec.usl.id"
          :style="editionMode ? 'background: #E3EFFA' : ''"
          v-model="form.mainFf.id"
          :items="spr.ff"
        ></v-autocomplete>
      </div>
    </div>
    <v-dialog v-model="invalidData" :width="errorWidth">
      <v-card>
        <v-card-title style="margin: 0 auto">
          {{ errorText }}
        </v-card-title>
      </v-card>
    </v-dialog>
    <div class="rating" style="flex-direction: column" v-if="blockOpened">
      <p class="bold" style="margin: 13px auto 0 5px" v-if="form.mainCourse === 1">
        Сведения об оценках претендента за промежуточную зимнюю сессию и оценки государственной итоговой аттестации
        предыдущего уровня подготовки (государственный экзамен и/или защита выпускной квалификационной работы)
      </p>
      <p class="bold" style="margin: 13px auto 0 5px" v-else>
        Сведения об оценках претендента (за 2 предыдущие промежуточные сессии)
      </p>
      <div style="display: flex; flex-direction: row">
        <div style="display: flex; flex-direction: row">
          <div class="inputDiv" style="width: 235px">
            <label>Общее количество оценок <span style="color: red">*</span></label>
            <v-text-field
              type="number"
              :disabled="!editionMode"
              v-model="form.mainScoreCount"
              :style="editionMode ? 'background: #E3EFFA' : ''"
            ></v-text-field>
            <p class="block__hint" style="align-self: unset; margin-top: 5px;" v-if="form.mainCourse === 1">Включая ГЭ
              и/или ВКР</p>
          </div>
          <div class="inputDiv" style="width: 235px">
            <label>Количество оценок «Отлично» <span style="color: red">*</span></label>
            <v-text-field
              type="number"
              :disabled="!editionMode"
              v-model="form.mainScore5Count"
              :style="editionMode ? 'background: #E3EFFA' : ''"
            ></v-text-field>
            <p class="block__hint" style="align-self: unset; margin-top: 5px;" v-if="form.mainCourse === 1">Включая ГЭ
              и/или ВКР</p>
          </div>
          <div class="inputDiv" style="width: 235px">
            <label>Количество оценок «Хорошо» <span style="color: red">*</span></label>
            <v-text-field
              type="number"
              :disabled="!editionMode"
              v-model="form.mainScore4Count"
              :style="editionMode ? 'background: #E3EFFA' : ''"
            ></v-text-field>
            <p class="block__hint" style="align-self: unset; margin-top: 5px;" v-if="form.mainCourse === 1">Включая ГЭ
              и/или ВКР</p>
          </div>
        </div>
      </div>
      <div style="display: flex; flex-direction: row">
        <div class="inputDiv" style="width: 755px; margin-right: 0">
          <label>
            {{
              form.mainCourse === 1 ? 'Наличие академической задолженности за промежуточную зимнюю сессию'
                : 'Наличие академической задолженности за весь период обучения'
            }}
            <span style="color: red">*</span>
          </label>
          <v-autocomplete
            :rules="[rules.required]"
            :disabled="!editionMode"
            :style="editionMode ? 'background: #E3EFFA' : ''"
            v-model="academicDebt"
            :items="academicDebtArray"
          ></v-autocomplete>
        </div>
      </div>
    </div>
    <div class="btnDiv" v-if="blockOpened">
      <!--  && (!isExpert || isManager)-->
      <button
        v-if="blockEditingInProcessComputed && !editionMode"
        class="usual-btn"
        @click="editionMode = true">
        Изменить
      </button>
      <button
        v-else-if="blockEditingInProcessComputed && editionMode"
        class="usual-btn" @click="sendInfo"
      >
        Сохранить
      </button>
      <button
        v-else
        class="usual-btn usual-btn_disabled"
      >
        Изменить
      </button>
      <p class="block__hint"><span style="color: red">*</span> Все поля, помеченные звездочкой, обязательны для
        заполнения</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "PersonInfoBlockMns",
  data() {
    return {
      spr: {
        ff: [],
        fo: [],
        spec: [],
        usl: [],
        stpType: [],
      },
      orgType: null,
      invalidData: false,
      loading: false,
      blockOpened: false,
      editionMode: false,
      academicDebt: 0,
      prevFio: false,
      form: {
        id: 0,
        mainFirstName: '',
        mainLastName: '',
        mainSecondName: '',
        mainGender: '',
        mainIsMns: '',
        mainSrokObuchenia: '',
        mainEdit2022OldLastName: '',
        mainEdit2022OldFirstName: '',
        mainEdit2022OldSecondName: '',
        mainEmail: '',
        mainPhoneNumber: '',
        orgName: '',
        mainCourse: 0,
        mainSpec: {id: '', usl: {id: ''}},
        mainEdit2022IsForeign: null,
        mainFo: {id: ''},
        mainFf: {id: ''},
        mainScoreCount: 0,
        mainScore4Count: 0,
        mainScore5Count: 0,
      },
      gendersArray: [
        {text: 'Мужской', value: 'MALE'},
        {text: 'Женский', value: 'FEMALE'},
      ],
      citizenshipArray: [
        {text: 'Российская Федерация', value: false},
        {text: 'Иностранное', value: true},
      ],
      isMnsOptions: [
        {text: 'Да', value: true},
        {text: 'Нет', value: false},
      ],
      academicDebtArray: [
        {text: 'Да', value: 2},
        {text: 'Нет', value: 1},
      ],
      coursesArray: [
        {text: '1 курс', value: 1},
        {text: '2 курс', value: 2},
        {text: '3 курс', value: 3},
        {text: '4 курс', value: 4},
        {text: '5 курс', value: 5},
        {text: '6 курс', value: 6},
      ],
      coursesFilteredArray: [],
      rules: {
        required: (value) => !!value || 'Обязательное поле!',
        nonNegativeFloat: (value) => value >= 0 || '*',
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Неверно указан адрес почты';
        },
      },
    };
  },
  props: {
    rowID: {
      type: String,
      default: '',
    },
    applicantBlocked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      getUser: 'getUser',
      getFakeUser: 'getFakeUser',
      isExpert: 'isExpert',
      isManager: 'isManager',
      // isCurator: 'isCurator',
    }),

    blockEditingInProcessComputed() {
      if (this.applicantBlocked) {
        return false
      } /*else if (!this.applicantBlocked && !this.editionMode) {
        return false
      } */ else {
        return true
      }
    },

    nonAspValidation() {
      if (this.form.mainLastName !== '' && this.form.mainGender !== '' && this.form.mainIsMns
        && this.form.mainEdit2022IsForeign === false && this.form.mainPhoneNumber !== '' && this.form.mainEmail !== ''
        && this.form.mainCourse !== 0 && this.form.mainSpec.usl?.id !== '' && this.form.mainSpec?.id !== ''
        && this.form.mainFo?.id !== '' && this.form.mainFf?.id !== '') {
        return true
      } else {
        return false
      }
    },

    scoreCountValidation() {
      if (this.form.mainScoreCount !== '' && this.form.mainScore5Count !== '' && this.form.mainScore4Count !== ''
        && Math.floor(this.form.mainScoreCount) >= Math.floor(this.form.mainScore5Count)
        && Math.floor(this.form.mainScoreCount) >= Math.floor(this.form.mainScore4Count)
        /*&& Math.floor(this.form.mainScore5Count) >= Math.floor(this.form.mainScore4Count)*/
        /*&& Math.floor(this.form.mainScore5Count) >= Math.floor(this.form.mainScoreCount) / 2*/
        && Math.floor(this.form.mainScoreCount) === Math.floor(this.form.mainScore4Count) + Math.floor(this.form.mainScore5Count)
        /*&& Math.floor(this.form.mainScoreCount) > 0*/) {
        return true
      } else {
        return false
      }
    },

    prevFioComputed() {
      if (this.prevFio && !this.form.mainEdit2022OldLastName && !this.form.mainEdit2022OldFirstName && !this.form.mainEdit2022OldSecondName) {
        return false
      } else if (!this.prevFio) {
        return true
      } else {
        return true
      }
    },

    errorText() {
      if (this.form.mainLastName === '') {
        return 'Необходимо указать фамилию'
      } else if (this.form.mainGender === '') {
        return 'Необходимо указать пол'
      } else if (this.form.mainEdit2022IsForeign !== false) {
        return 'Указанное гражданство не соответствует критериям отбора'
      } else if (!this.form.mainIsMns) {
        return 'Указанная Принадлежность к КМНС не соответствует критериям отбора'
      } else if (this.form.mainEmail === '') {
        return 'Необходимо указать адрес электронной почты'
      } else if (this.form.mainPhoneNumber === '') {
        return 'Необходимо указать номер телефона'
      } else if (this.form.mainSpec.usl?.id === '') {
        return 'Необходимо указать уровень подготовки'
      } else if (this.form.mainCourse === 0) {
        return 'Необходимо указать курс/год обучения'
      } else if (this.form.mainSpec?.id === '') {
        return 'Необходимо указать направление подготовки'
      } else if (this.form.mainFo?.id === '') {
        return 'Необходимо указать форму обучения'
      } else if (this.form.mainFf?.id === '') {
        return 'Необходимо указать форму финансирования'
      } else if (!this.scoreCountValidation) {
        return 'Количество оценок указано неверно'
      } else if (this.academicDebt !== 1) {
        return 'Успеваемость не соответствует критериям отбора'
      } else if (this.prevFio && !this.form.mainEdit2022OldLastName && !this.form.mainEdit2022OldFirstName && !this.form.mainEdit2022OldSecondName) {
        return 'Заполните прежние ФИО'
      } else if (this.form.mainCourse && !this.form.mainSrokObuchenia) {
        return 'Необходимо заполнить срок обучения';
      } else {
        return 'Требуется заполнить все обязательные поля'
      }
    },

    errorWidth() {
      if (this.form.mainLastName === '') {
        return '350px'
      } else if (this.form.mainGender === '') {
        return '290px'
      } else if (this.form.mainEdit2022IsForeign !== false) {
        return '645px'
      } else if (!this.form.mainIsMns) {
        return '770px'
      } else if (this.form.mainEmail === '') {
        return '515px'
      } else if (this.form.mainPhoneNumber === '') {
        return '420px'
      } else if (this.form.mainSpec.usl?.id === '') {
        return '450px'
      } else if (this.form.mainCourse === 0) {
        return '440px'
      } else if (this.form.mainSpec?.id === '') {
        return '500px'
      } else if (this.form.mainFo?.id === '') {
        return '415px'
      } else if (this.form.mainFf?.id === '') {
        return '490px'
      } else if (!this.scoreCountValidation) {
        return '410px'
      } else if (this.academicDebt !== 1) {
        return '550px'
      } else if (this.prevFio && !this.form.mainEdit2022OldLastName && !this.form.mainEdit2022OldFirstName && !this.form.mainEdit2022OldSecondName) {
        return '295px'
      } else if (this.form.mainCourse && !this.form.mainSrokObuchenia) {
        return '440px';
      } else {
        return '490px'
      }
    }
  },

  created() {
    if (this.rowID !== '0') {
      this.getRow();
      this.blockOpened = false;
    } else {
      Promise.allSettled([this.getSprFO(), this.getSprFF()])
        .then(() => {
          this.editionMode = true;
          this.blockOpened = true;
        })
    }
    this.getOrgType();
    this.getSprUsl();

    if (this.isExpert) {
      this.blockOpened = true;
    }
  },

  methods: {
    ...mapActions('keycloak', {setLoadingStatus: 'setLoadingStatus'}),
    async sendInfo() {
      let url = `/api/2024/mns/add?orgId=${this.getUser.orgID ? this.getUser.orgID : this.getFakeUser.id}`;
      if (this.form.id !== 0) {
        url = `/api/2024/mns/update?orgId=${this.getUser.orgID ? this.getUser.orgID : this.getFakeUser.id}`;
      }
      if (this.nonAspValidation && this.scoreCountValidation && this.prevFioComputed && this.academicDebt === 1) {
        try {
          const res = await axios.post(url, {
            "id": this.form.id !== 0 ? this.form.id : '',
            "mainOrg": {"id": this.getUser.orgID ? this.getUser.orgID : this.getFakeUser.id},
            "mainLastName": this.form.mainLastName,
            "mainFirstName": this.form.mainFirstName,
            "mainSecondName": this.form.mainSecondName,
            "mainIsMns": this.form.mainIsMns,
            "mainEdit2022OldFirstName": this.prevFio ? this.form.mainEdit2022OldFirstName : '',
            "mainEdit2022OldSecondName": this.prevFio ? this.form.mainEdit2022OldSecondName : '',
            "mainEdit2022OldLastName": this.prevFio ? this.form.mainEdit2022OldLastName : '',
            "mainEmail": this.form.mainEmail,
            "mainSrokObuchenia": this.form.mainSrokObuchenia,
            "mainPhoneNumber": this.form.mainPhoneNumber,
            "mainCourse": this.form.mainCourse, // Курс от 1 до 6 селектором
            "mainGender": this.form.mainGender, // Пол по справочнику
            "mainFo": {"id": this.form.mainFo.id}, // Очная, очно-заочная, заочная из справочника Fo
            "mainFf": {"id": this.form.mainFf.id}, // Федеральный бюджет / платное из справочника Ff
            "mainSpec": {"id": this.form.mainSpec.id}, // Справочник специальностей
            "mainScoreCount": this.form.mainScoreCount, // Всего оценок
            "mainScore5Count": this.form.mainScore5Count, // Всего 5
            "mainScore4Count": this.form.mainScore4Count, // Всего 4
            "mainEdit2022IsForeign": this.form.mainEdit2022IsForeign, // Является ли студент иностранцем
          }, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
          if (res.data) { // меняем путь на новосозданную строку, если она создавалась
            let ID = res.data.id;

            if (!this.prevFio) {
              this.form.mainEdit2022OldLastName = null;
              this.form.mainEdit2022OldFirstName = null;
              this.form.mainEdit2022OldSecondName = null;
            }

            this.$emit("update:rowID", `${ID}`); // для block1?
            this.$emit("getAll");

            if (this.rowID === '0') {
              console.log('addRow успех');
              // router: 'justCreated' - параметр для определения только что созданного файла, для возврата из него
              // нужно вызывать this.$router.go(-2) вместо this.$router.back();
              await this.$router.push({name: 'ApplicantFormMns2024', params: {rowID: `${ID}`, router: 'justCreated'}});
              await this.getRow();
            } else {
              console.log('updateRow успех');
            }
            this.editionMode = false;
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        this.invalidData = true
      }
    },

    async getRow() {
      this.loading = true;
      try {
        const res = await axios.get(`/api/2024/mns/getById?rowId=${this.rowID}`, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          console.log('getRow успех');
          await Promise.allSettled([this.getSprFO(), this.getSprFF()]);
          this.form = res.data[0];

          if (this.form.mainEdit2022OldLastName || this.form.mainEdit2022OldFirstName || this.form.mainEdit2022OldSecondName) {
            this.prevFio = true
          }

          this.academicDebt = 1;

          await this.levelChange(this.form.mainSpec.usl.id, 'initial');
          await this.getSprSpec('initial');

          this.form.mainSpec = res.data[0].mainSpec;

          this.loading = false;
        }
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },

    async getOrgType() {
      this.orgType = null;
      try {
        const res = await axios.get(`/api/cms/spr/org/byId/get?orgId=${this.getUser.orgID ? this.getUser.orgID : this.getFakeUser.id}`, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          this.orgType = res.data.orgFounder.id;
          console.log('getOrgType успех');
        }
      } catch (e) {
        console.log(e);
      }
    },

    async getSprFF() {
      this.spr.ff = [];
      try {
        const res = await axios.get(`/api/cms/spr/ff/get14`, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          for (let i = 0; i < res.data.length; i++) {
            this.spr.ff.push({
              text: res.data[i].name,
              value: res.data[i].id
            })
          }

          console.log('getSprFF успех');
        }
      } catch (e) {
        console.log(e);
      }
    },

    async getSprFO() {
      this.spr.fo = [];
      try {
        const res = await axios.get(`/api/cms/spr/fo/get`, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          for (let i = 0; i < res.data.length; i++) {
            this.spr.fo.push({
              text: res.data[i].name,
              value: res.data[i].id
            })
          }

          console.log('getSprFO успех');
        }
      } catch (e) {
        console.log(e);
      }
    },

    async getSprSpec(arg) {
      try {
        const res = await axios.get(`/api/cms/spr/spec/byUslId/get?uslId=${this.form.mainSpec.usl.id}`, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) { //reduce
          this.spr.spec = [];

          if (arg === 'edit') {
            this.form.mainSpec = {id: '', usl: this.form.mainSpec.usl};
          }

          let sortedData = res.data.sort((a, b) => a.kodSpec.replace(/[.]/g, "") - b.kodSpec.replace(/[.]/g, ""));

          for (let i = 0; i < sortedData.length; i++) { //regexp
            this.spr.spec.push({
              text: `${sortedData[i].kodSpec} - ${sortedData[i].name}`, //${res.data[i].usl.name},
              value: sortedData[i].id
            })
          }
          console.log('getSprSpec успех');
        }
      } catch (e) {
        console.log(e);
      }
    },

    async getSprUsl() {
      try {
        const res = await axios.get(`/api/cms/spr/usl/get`, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          for (let i = 0; i < res.data.length; i++) {
            this.spr.usl.push({
              text: res.data[i].name,
              value: res.data[i].id
            })
          }

          this.spr.usl = this.spr.usl.filter(el => el.value !== 6);

          console.log('getSprUsl успех');
        }
      } catch (e) {
        console.log(e);
      }
    },

    levelChange(arg, arg2) {
      if (arg === 1 || arg === 2) {
        this.coursesFilteredArray = this.coursesArray.filter(el => el.value !== 1)
      } else if (arg === 3) {
        this.coursesFilteredArray = this.coursesArray.filter(el => el.value !== 6)
      } else {
        this.coursesFilteredArray = this.coursesArray
      }

      if (arg2 !== 'initial') {
        this.getSprSpec('edit');
      }
    }
  }
}
</script>

<style scoped>

</style>